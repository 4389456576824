import { Button, Card, Col, Form, Input, Row, Select, Spin, Table, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { DatePickerCol, LayoutNav } from 'components/layout.component';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { DATE_RANGE_LIMIT } from 'enum/date';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { $get } from 'services';
import * as common from 'utils/common';
import { toFormatNumber as $f } from 'utils/common';

// 會員數據
// 同步於 BO 代理統計分析

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
  legend: {
    display: false
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem: any) {
        return tooltipItem.yLabel;
      }
    }
  }
};

enum TAB {
  代理 = '1',
  會員 = '2',
  遊戲 = '3'
}

enum GAME_TYPE_ID {
  SLOT = 1,
  LIVE = 2,
  SPORTS = 3,
  ESPORTS = 4,
  POKER = 5,
  FISHING = 6,
  COCKFIGHT = 7,
  LOTTERY = 8,
  EGAMES = 9,
}

const PageMain: React.FC = () => {
  const { data: site } = useSite();
  const [activeKey, setActiveKey] = useState<string>(TAB.代理);
  const [form] = useForm();

  const items: TabsProps['items'] = [
    {
      key: TAB.代理,
      label: <div className="text-center w-5">{i18n.t('agent')}</div>
    },
    {
      key: TAB.會員,
      label: <div className="text-center w-5">{i18n.t('member')}</div>
    },
    {
      key: TAB.遊戲,
      label: <div className="text-center w-5">{i18n.t('game')}</div>
    },
  ];

  const onTabClick = (key: string) => {
    setParams({ ...params, PageIndex: 1 });
    setActiveKey(key);
    updateTotal(key);
  }

  const [total, setTotal] = useState({
    key: 1,
    TotalCount: 0,
    TotalValidBetAmount: 0,
    TotalWinLoss: 0,
  });

  const updateTotal = (key: any) => {
    switch (key) {
      case TAB.代理:
        setTotal({
          key: 1,
          TotalCount: agentList?.TotalCount,
          TotalValidBetAmount: agentList?.TotalValidBetAmount,
          TotalWinLoss: agentList?.TotalWinLoss,
        })
        break;
      case TAB.會員:
        setTotal({
          key: 1,
          TotalCount: memberList?.TotalCount,
          TotalValidBetAmount: memberList?.TotalValidBetAmount,
          TotalWinLoss: memberList?.TotalWinLoss,
        })
        break;
      case TAB.遊戲:
        setTotal({
          key: 1,
          TotalCount: gameList?.TotalCount,
          TotalValidBetAmount: gameList?.TotalValidBetAmount,
          TotalWinLoss: gameList?.TotalWinLoss,
        })
        break;
    }
  }

  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD'),
    dayjs().endOf('day').format('YYYY-MM-DD'),
  ]);
  const [initDate, setInitDate] = useState<boolean>(false);
  const [params, setParams] = useState<any>({
    PageIndex: 1,
    PageSize: 10,
    StartDate: date[0],
    EndDate: date[1],
  });
  const { data: agentList, mutate: mutateAgent, isValidating: agentValidating } = $get({
    url: 'api/betticket/data/agent',
    params,
  })
  const { data: memberList, mutate: mutateMember, isValidating: memberValidating } = $get({
    url: 'api/betticket/data/member',
    params
  })
  const { data: gameList, mutate: mutateGame, isValidating: gameValidating } = $get({
    url: 'api/betticket/data/game',
    params
  })
  // 遊戲大類
  const { data: category } = $get({
    url: 'api/category/list',
    params: { Status: 1 }
  })

  const allValidating = agentValidating && memberValidating && gameValidating
  useEffect(() => {
    updateTotal(activeKey)
  }, [agentList, memberList, gameList]);

  const search = (formData: any) => {
    setParams({
      StartDate: date[0],
      EndDate: date[1],
      PageIndex: 1,
      PageSize: params.PageSize,
      AgentAccount: formData.AgentAccount,
      MemberAccount: formData.MemberAccount,
      CategoryId: formData.CategoryId,
    })
    mutateAgent();
    mutateMember();
    mutateGame();
  }

  const onClear = () => {
    form.resetFields();
    setParams({
      ...params,
      PageIndex: 1,
      PageSize: 10,
    })
    setInitDate(!initDate);
  }

  const listToPieData = (valField: any, nameField: any, providerCode: any, Category: any, labelName?: any) => {
    const num = (ary: any) => (
      ary?.map((item: any) => new Decimal(item[valField]))
    )
    let list = [];
    let totalType = 0;
    switch (activeKey) {
      case '1':
        list = agentList?.Data
        totalType = agentList ? num(agentList?.Data)?.reduce((acc: any, num: any) => acc.plus(num), new Decimal(0)) : 0;
        break;
      case '2':
        list = memberList?.Data;
        totalType = memberList ? num(memberList?.Data)?.reduce((acc: any, num: any) => acc.plus(num), new Decimal(0)) : 0;
        break;
      case '3':
        list = gameList?.Data;
        totalType = gameList ? num(gameList?.Data)?.reduce((acc: any, num: any) => acc.plus(num), new Decimal(0)) : 0;
        break;
    }
    return {
      labels: list ? list.map((item: any) => `${common.specialProviderName(item[nameField], site.SiteName, item[providerCode])}${i18n.t(item[Category])}`) : [],
      datasets: [
        {
          label: labelName && `${labelName} ${i18n.t('percentage')}`,
          data: list ? list?.map((item: any) => new Decimal(item[valField]).dividedBy(totalType).times(100).toDecimalPlaces(2)) : [],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    }
  }

  const listToBarData = (valField: any, nameField: any, providerCode: any, Category: any) => {
    let list = [];
    switch (activeKey) {
      case '1':
        list = agentList?.Data;
        break;
      case '2':
        list = memberList?.Data;
        break;
      case '3':
        list = gameList?.Data;
        break;
    }
    return {
      labels: list ? list.map((item: any) => `${common.specialProviderName(item[nameField], site.SiteName, item[providerCode])}${i18n.t(item[Category])}`) : [],
      datasets: [
        {
          data: list ? list?.map((item: any) => item[valField]) : [],
          label: i18n.t('totalWinLossAmounts'),
          fill: true,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    }
  }

  const handleActiveMember = (member: string) => {
    form.setFieldsValue({
      AgentAccount: null,
      MemberAccount: member,
      CategoryId: null,
    })
    setParams({
      MemberAccount: member,
      StartDate: date ? date[0] : dayjs().startOf('day').format('YYYY-MM-DD'),
      EndDate: date ? date[1] : dayjs().endOf('day').format('YYYY-MM-DD'),
      PageIndex: 1,
      PageSize: params.PageSize,
    })
  };

  const handleActiveGame = (game: number) => {
    form.setFieldsValue({
      AgentAccount: null,
      MemberAccount: null,
      CategoryId: GAME_TYPE_ID[game],
    })
    setParams({
      CategoryId: GAME_TYPE_ID[game],
      StartDate: date ? date[0] : dayjs().startOf('day').format('YYYY-MM-DD'),
      EndDate: date ? date[1] : dayjs().endOf('day').format('YYYY-MM-DD'),
      PageIndex: 1,
      PageSize: params.PageSize,
    })
  };

  const searchSpan = common.$mobile ? 24 : 6
  const ChartSpan = common.$mobile ? 24 : 12

  return (
    <>
      <LayoutNav />
      <Content>
        <Form className='form-search' form={form} onFinish={search}>
          <Row gutter={[12, 12]} style={{ maxWidth: '800px' }}>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col span={searchSpan}>
                  <Form.Item name="AgentAccount">
                    <Input placeholder={`${i18n.t('agentAccount')}`} />
                  </Form.Item>
                </Col>
                <Col span={searchSpan}>
                  <Form.Item name="MemberAccount">
                    <Input placeholder={`${i18n.t('memberAccount')}`} />
                  </Form.Item>
                </Col>
                <Col span={searchSpan}>
                  <Form.Item name="CategoryId">
                    <Select
                      placeholder={`${i18n.t('gameCategory')}`}
                      options={
                        category && category.Data &&
                        [
                          { value: '', label: i18n.t('ALL') },
                          ...category.Data.map((item: any) => ({
                            key: item.Id,
                            value: item.Id,
                            label: i18n.t(`${item.Code}`)
                          }))
                        ]
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <DatePickerCol span={searchSpan} initDate={initDate} date={date} setDate={setDate} format={'YYYY-MM-DD'}
              textAlign={'right'} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            {/* 按鈕 */}
            <Col span={24}>
              <Row justify={common.$mobile ? 'end' : 'start'} gutter={[12, 12]}>
                <Col>
                  <Button type="primary" onClick={form.submit}>{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        <Row align="middle" className="mt-1" gutter={[0, 18]}>
          {/* 總覽 */}
          <Col span={24}>
            <Tabs centered={common.$mobile} items={items} activeKey={activeKey} onTabClick={onTabClick} />
            <Table
              size="middle"
              loading={allValidating}
              dataSource={[total]}
              columns={[
                {
                  dataIndex: 'TotalCount',
                  title: i18n.t('totalBetCount'),
                  align: 'center',
                  width: '33%',
                  render: (val) => $f(val, 0)
                },
                {
                  dataIndex: 'TotalValidBetAmount',
                  title: i18n.t('totalValidBet'),
                  align: 'center',
                  width: '33%',
                  render: (val) => $f(val, 0)
                },
                {
                  dataIndex: 'TotalWinLoss',
                  title: i18n.t('totalWinLossAmount'),
                  align: 'center',
                  width: '34%',
                  render: (val) => <div className={common.numColor03(val)}>{$f(val)}</div>
                },
              ]}
              pagination={false}
            />
          </Col>
          {/* 圖表 */}
          <Col span={24}>
            <Spin spinning={allValidating}>
              <Row gutter={[18, 18]}>
                <Col span={ChartSpan}>
                  <Card>
                    {i18n.t('totalBets')}
                    <Row justify={'center'}>
                      <Col style={{ width: '300px' }}>
                        <Pie data={activeKey === TAB.遊戲
                          ? listToPieData('bet_count', 'GameProvider', 'ProviderCode', 'GameCategory', i18n.t('totalBets'))
                          : listToPieData('bet_count', 'Account', null, i18n.t('totalBets'))} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={ChartSpan}>
                  <Card>
                    {i18n.t('betAmountValid')}
                    <Row justify={'center'}>
                      <Col style={{ width: '300px' }}>
                        <Pie data={activeKey === TAB.遊戲
                          ? listToPieData('ValidBetAmount', 'GameProvider', 'ProviderCode', 'GameCategory', i18n.t('betAmountValid'))
                          : listToPieData('ValidBetAmount', 'Account', null, i18n.t('betAmountValid'))} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={ChartSpan}>
                  <Card>
                    {i18n.t('gameProfit')}
                    <Bar height={120} options={options} data={activeKey === TAB.遊戲
                      ? listToBarData('WinLossAmount', 'GameProvider', 'ProviderCode', 'GameCategory',)
                      : listToBarData('WinLossAmount', 'Account', null, null)} />
                  </Card>
                </Col>
                {activeKey === TAB.遊戲 &&
                  <Col span={ChartSpan}>
                    <Card>
                      {i18n.t('totalBetMoney')}
                      <Row justify={'center'}>
                        <Col style={{ width: '300px' }}>
                          <Pie data={listToPieData('BetAmount', 'GameProvider', 'ProviderCode', 'GameCategory', i18n.t('totalBetMoney'))} />
                        </Col>
                      </Row>
                    </Card>
                  </Col>}
              </Row>
            </Spin>
          </Col>
          {/* 數據 */}
          <Col span={24}>
            {/* 代理 */}
            {activeKey === TAB.代理 &&
              <Table
                size="middle"
                scroll={{ x: 400 }}
                loading={agentValidating}
                dataSource={agentList?.Data.map((item: any) => ({ ...item, key: item.id }))}
                columns={[
                  {
                    dataIndex: 'Account',
                    title: i18n.t('agentAccount'),
                    fixed: 'left',
                    width: 150,
                    render: (val, record: any) => {
                      const word = common.agentLevelWord(record.Level);
                      return `${word}／${val}`
                    }
                  },
                  {
                    dataIndex: 'up_count',
                    title: i18n.t('teamBetCount'),
                    align: 'right',
                    width: 150,
                    // sorter: (a, b) => a.teamBet - b.teamBet,
                    render: (val) => $f(val, 0)
                  },
                  {
                    dataIndex: 'up_BetAmount',
                    title: i18n.t('teamValidBets'),
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.teamValidBet - b.teamValidBet,
                    render: (val) => $f(val)
                  },
                  {
                    dataIndex: 'up_WinLossAmount',
                    title: i18n.t('teamWinLossAmount'),
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.teamWinAndLoss - b.teamWinAndLoss,
                    render: (val) => $f(val)
                  },
                  {
                    dataIndex: 'bet_count',
                    title: i18n.t('betCounts'),
                    align: 'right',
                    width: 150,
                    // sorter: (a, b) => a.betTotal - b.betTotal,
                    render: (val) => $f(val, 0)
                  },
                  {
                    dataIndex: 'ValidBetAmount',
                    title: i18n.t('betAmountValid'),
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.validBetTotal - b.validBetTotal,
                    render: (val) => $f(val)
                  },
                  {
                    dataIndex: 'WinLossAmount',
                    title: i18n.t('totalWinLossAmounts'),
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.winAndLoss - b.winAndLoss,
                    render: (val) => <div className={common.numColor03(val)}>{$f(val)}</div>
                  },
                ]}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                  total: agentList?.TotalRecord,
                  pageSize: params.PageSize
                }}
                onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
              />
            }
            {/* 會員 */}
            {activeKey === TAB.會員 &&
              <Table
                size="middle"
                scroll={{ x: 400 }}
                loading={memberValidating}
                dataSource={memberList?.Data.map((item: any) => ({ ...item, key: item.id }))}
                columns={[
                  {
                    dataIndex: 'Account',
                    title: i18n.t('memberAccount'),
                    width: 150,
                    fixed: 'left',
                    render: (val) => val.includes('**') ? val
                      : <Button style={{ padding: 0 }} className="size-12" type="link" onClick={() => handleActiveMember(val)}>
                        {val}
                      </Button>
                  },
                  {
                    dataIndex: 'up_count',
                    title: i18n.t('teamBetCount'),
                    align: 'right',
                    width: 150,
                    render: (val) => $f(val, 0)
                  },
                  {
                    dataIndex: 'up_BetAmount',
                    title: i18n.t('teamValidBets'),
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.teamValidBet - b.teamValidBet,
                    render: (val) => $f(val)
                  },
                  {
                    dataIndex: 'up_WinLossAmount',
                    title: i18n.t('teamWinLossAmount'),
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.teamWinAndLoss - b.teamWinAndLoss,
                    render: (val) => $f(val)
                  },
                  {
                    dataIndex: 'bet_count',
                    title: i18n.t('betCounts'),
                    align: 'right',
                    width: 150,
                    // sorter: (a, b) => a.betTotal - b.betTotal,
                    render: (val) => $f(val, 0)
                  },
                  {
                    dataIndex: 'ValidBetAmount',
                    title: i18n.t('betAmountValid'),
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.validBetTotal - b.validBetTotal,
                    render: (val) => $f(val)
                  },
                  {
                    dataIndex: 'WinLossAmount',
                    title: i18n.t('totalWinLossAmounts'),
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.winAndLoss - b.winAndLoss,
                    render: (val) => <div className={common.numColor03(val)}>{$f(val)}</div>
                  },
                ]}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                  total: memberList?.TotalRecord,
                  pageSize: params.PageSize
                }}
                onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
              />
            }
            {/* 遊戲 */}
            {activeKey === TAB.遊戲 &&
              <Table
                size="middle"
                scroll={{ x: 400 }}
                loading={gameValidating}
                dataSource={gameList?.Data.map((item: any) => ({ ...item, key: item.GameProvider }))}
                columns={[
                  {
                    dataIndex: 'GameProvider',
                    title: `${i18n.t('gameProvider')}／${i18n.t('gameCategory')}`,
                    width: 150,
                    fixed: 'left',
                    render: (val, { GameCategory, ProviderCode }) => (
                      <Button
                        style={{ padding: 0 }} className="size-12" type="link" onClick={() => handleActiveGame(GameCategory)
                        }>
                        {`${common.specialProviderName(val, site.SiteName, ProviderCode)}／${i18n.t(GameCategory)}`}
                      </Button>
                    )
                  },
                  {
                    dataIndex: 'bet_count',
                    title: i18n.t('betCounts'),
                    align: 'right',
                    width: 150,
                    // sorter: (a, b) => a.betTotal - b.betTotal,
                    render: (val) => $f(val, 0)
                  },
                  {
                    dataIndex: 'ValidBetAmount',
                    title: i18n.t('betAmountValid'),
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.validBetTotal - b.validBetTotal,
                    render: (val) => $f(val)
                  },
                  {
                    dataIndex: 'WinLossAmount',
                    title: i18n.t('totalWinLossAmounts'),
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.winAndLoss - b.winAndLoss,
                    render: (val) => <div className={common.numColor03(val)}>{$f(val)}</div>
                  },
                ]}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                  total: gameList?.TotalRecord,
                  pageSize: params.PageSize
                }}
                onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
              />
            }
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default PageMain;
