export const GATEWAY_CODE_WITHDRAW = {
  銀行卡: "WITHDRAW_GATE_BANK",
  虛擬貨幣: "WITHDRAW_GATE_CRYPTO",
}

export const DEPOSIT_GATE_MAP = {
  DEPOSIT_GATE_BANK: 'bank',
  DEPOSIT_GATE_CVS: 'cvs',
  DEPOSIT_GATE_ATM: 'atm',
  DEPOSIT_GATE_QR: 'qr',
  DEPOSIT_GATE_DC: 'dc',
  DEPOSIT_GATE_CC: 'cc'
}